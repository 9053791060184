.border-none[data-v-34fd896c]:before {
  display: none;
}
.border-none[data-v-34fd896c]:after {
  display: none;
}
.flex-fix[data-v-34fd896c] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-34fd896c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-34fd896c] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-34fd896c] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-34fd896c] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-34fd896c] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-34fd896c] {
  *zoom: 1;
}
.clear-fix[data-v-34fd896c]:before,
.clear-fix[data-v-34fd896c]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.item-list[data-v-34fd896c] {
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 374px) {
.item-list .had-stage[data-v-34fd896c] {
    height: 5.2rem;
}
}
@media screen and (min-width: 321px) {
.item-list .had-stage[data-v-34fd896c] {
    height: 5.2rem;
}
}
.item-list .no-stage[data-v-34fd896c] {
  height: 4.8rem;
}
.item-list .zjg-li[data-v-34fd896c] {
  height: 5.2rem;
}
.item-list .item[data-v-34fd896c] {
  width: 47.5%;
  margin-top: 0.3rem;
  padding-bottom: 0.15rem;
  float: left;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 0;
}
.item-list .item .img-box[data-v-34fd896c] {
  width: 100%;
  height: 3.3rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.item-list .item .img-box .img[data-v-34fd896c] {
  width: 100%;
  height: 100%;
}
.item-list .item .sales[data-v-34fd896c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0 0.1rem;
}
.item-list .item .item-name[data-v-34fd896c] {
  height: 0.75rem;
  padding: 0 0.2rem;
  margin-bottom: 0.12rem;
  font-size: 0.26rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.36rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.item-list .item .item-name .sourceName[data-v-34fd896c] {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.15rem;
  border-radius: 0.18rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.item-list .item .zjg-max[data-v-34fd896c] {
  color: var(--main-color);
  padding-left: 0.2rem;
  font-size: 0.2rem;
}
.item-list .item .priceWithBtn[data-v-34fd896c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0.2rem;
}
.item-list .item .priceWithBtn .item-price[data-v-34fd896c] {
  padding-left: 0.2rem;
  /* position: absolute;
				bottom: 0.26rem; */
}
.item-list .item .priceWithBtn .item-price .price[data-v-34fd896c] {
  color: #333;
  line-height: 0.28rem;
  font-weight: bold;
}
.item-list .item .priceWithBtn .item-price .price .sign[data-v-34fd896c] {
  font-size: 0.24rem;
}
.item-list .item .priceWithBtn .item-price .price .num[data-v-34fd896c] {
  font-size: 0.32rem;
}
.item-list .item .priceWithBtn .buy-btn[data-v-34fd896c] {
  position: absolute;
  right: 0;
  bottom: 0.17rem;
  width: 0.9rem;
  height: 0.48rem;
  padding-left: 0.26rem;
  padding-top: 0.08rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 2rem 0px 0px 2rem;
  z-index: 2;
}
.item-list .item .lusse[data-v-34fd896c] {
  padding: 0 0.2rem;
  color: #000;
}
.item-list .item .lusse .plans[data-v-34fd896c] {
  color: #fe5d1c;
}
.item-list .item .lusse .money[data-v-34fd896c] {
  font-size: 0.4rem;
}
.item-list .item .lusse .original-price[data-v-34fd896c] {
  margin-top: 0.15rem;
  color: #999999;
}
.item-list .item .benefit-wrap[data-v-34fd896c] {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0.36rem;
  border: 1px solid var(--main-color);
  border-radius: 0.18rem;
  z-index: 2;
}
.item-list .item .benefit-wrap .benefit[data-v-34fd896c] {
  font-size: 0.22rem;
  color: var(--main-color);
  line-height: 0.32rem;
  white-space: nowrap;
}
.item-list .item[data-v-34fd896c]:nth-child(odd) {
  margin-right: 4%;
}
